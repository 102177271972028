import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBanks, getCarrito, getMoneyChange } from "../../../redux/actions";
import axios from "axios";
import modoQr from "../../../assets/modo-qr.png";
import jwt from 'jsonwebtoken';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CardDatos from "../Datos/cardDatos/CardDatos";
import cartImg from '../../../assets/carrito_Enough.svg';
import plus from '../../../assets/__plus.svg';
import minus from '../../../assets/__minus.svg';
import { trackEvent } from "../../../utils/fbpPixel";
import Select from 'react-select';

const JWT_SECRET = 'tu_clave_secreta';

async function setCarritoYRedirigir(carrito, url) {
    try {
        await new Promise((resolve, reject) => {
            try {
                localStorage.setItem('carrito', JSON.stringify(carrito));
                resolve();
            } catch (error) {
                reject(error);
            }
        });
        window.open(url, '_self');
    } catch (error) {
        console.error('Error al establecer el carrito en localStorage:', error);
    }
}

export default function PagoDatos({ setStep, step, cuponTrue, setCuponTrue, envio, setCuponProductos, cuponProductos }) {
    const location = useLocation();
    const [formErrors, setFormErrors] = useState({});
    const searchParams = new URLSearchParams(location.search);
    const pass = searchParams.get('pass');
    const [isOpen, setIsOpen] = useState(false);
    const errorQuery = searchParams.get('error');
    const [cupon, setCupon] = useState();
    const [idPagoTransaccion, setIdPagoTransaccion] = useState();
    const [errorPago, setErrorPago] = useState(true);
    const precioDolar = useSelector((state) => state.dolarPrice);
    const carritoSinFiltro = useSelector((state) => state.carrito);;
    const carrito = carritoSinFiltro.filter((item) => Number(item.precio_dolar) !== 0);
    const token = localStorage.getItem('token');
    const [localidades, setLocalidades] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [errorCupon, setErrorCupon] = useState(false);
    const [loading, setLoading] = useState(false);
    const domicilio = JSON.parse(localStorage.getItem('domicilio'));
    const retiro = localStorage.getItem('personal');
    const [loadingBuy, setLoadingBuy] = useState(false);
    localStorage.setItem('dolar', precioDolar);
    const [medioPago, setMedioPago] = useState('20');
    const dispatch = useDispatch();
    const [user, setUser] = useState('');
    const dolar = useSelector((state) => state.dolarPrice);
    const [banks, setBanks] = useState();

    const axiosPost = async (url, data) => {
        return await axios.post(url, {
            httpMethod: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            isBase64Encoded: false,
        });
    };

    const [comprobante, setComprobante] = useState({
        txtArchivoDocumento: '',
        importe: 0,
        banco: 0,
        fecha: ''
    });

    const options = banks && banks.length > 0 && banks.map(item => ({
        value: item.idbanco,
        label: item.nombre
    }));

    const handleChangeSelect = (selectedOption) => {
        setComprobante({
            ...comprobante,
            banco: selectedOption ? selectedOption.value : 0
        });
        setFormErrors({
            ...formErrors,
            banco: null
        });
    };
    
    const selectedBank = options?.find(option => option.value === comprobante.banco) || null;
    
    useEffect(() => {
        fetchProvincias();
        fetchLocalidades(domicilio?.fk_idprov);

    }, [])

    const fetchLocalidades = async (fk_idprov) => {
        const response = await axiosPost('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', { fk_idprov });
        const localidadesData = JSON.parse(response?.data?.body);
        setLocalidades(localidadesData);
    };

    const fetchProvincias = async () => {
        const data = await getAllBanks();
        setBanks(data);
        console.log(data)

        const response = await fetch('https://api.depcsuite.com/td/getProvincias?id=12', {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        setProvincias(await response.json());
    };

    const validateForm = () => {
        const requiredFields = ['txtArchivoDocumento', 'banco', 'fecha'];
        const errors = {};
        requiredFields.forEach((field) => {
            if (!comprobante[field]) errors[field] = 'Este campo es requerido';
        });

        return errors;
    };

    const handleSubmit = async (e) => {
        setLoadingBuy(true);
        const errors = validateForm();
        if (Object.keys(errors).length) {
            setLoadingBuy(false);
            return;
        }

        if (medioPago == '1') {
            if (comprobante.txtArchivoDocumento === '' || comprobante.banco === 0 || comprobante.fecha === '') {
                setErrorPago(true);
                setLoadingBuy(false);
                return;
            } else {
                await subirComprobante();
            }
        }
        let link = '';
        if (medioPago == '1') {
            link = '/carrito?formaDePago=Transferencia&step=4';
        } else {
            link = '/carrito?formaDePago=Modo&step=4';
        }
        localStorage.setItem('mediopago', medioPago);
        localStorage.setItem('total', Number(
            (
                (medioPago === '1' && medioPago)
                    ? (cuponTrue ? (precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)) : (precioTotalDescuento + (Number(precioTotalOferta) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)))
                    : (cuponTrue ? (precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)) : (precioTotal + (Number(precioTotalOferta) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)))
            )
        ));
        localStorage.setItem('envio', envio);
        localStorage.setItem('totalPrecio', (medioPago == 1 ? precioTotalDescuento - precioTotal : precioTotal));
        trackEvent('InitiateCheckout', {
            content_ids: carrito.map(item => String(item.idproducto)), // IDs de productos en el carrito
            content_type: 'product', // Tipo de contenido
            value: (
                (medioPago === '1' && medioPago)
                    ? (cuponTrue ? (precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)) : (precioTotalDescuento + (Number(precioTotalOferta) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)))
                    : (cuponTrue ? (precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)) : (precioTotal + (Number(precioTotalOferta) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)))
            ),
            currency: 'ARS' // Moneda
        });
        scrollToTop();
        setCarritoYRedirigir(carrito, link);
    }

    const handleChangeInput = (e) => {
        const { name, value, type, files } = e.target;
        setComprobante({
            ...comprobante,
            [name]: type === 'file' ? files[0] : value
        });
        setFormErrors({ ...formErrors, [name]: null });
    };

    useEffect(() => {
        dispatch(getCarrito(token))
        if (token) {
            try {
                const decodedToken = jwt.verify(token, JWT_SECRET);
                setUser(decodedToken.userId);
            } catch (error) {
                console.error('Token no válido o caducado:', error.message);
            }
        }
        localStorage.removeItem('mediopago');
        let timer;
        timer = setTimeout(() => {
            setLoading(true);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const subirComprobante = async () => {
        setLoadingBuy(true);
        const formData = new FormData();
        formData.append('txtArchivoDocumento', comprobante.txtArchivoDocumento);
        formData.append('importe', comprobante.importe || Number(((medioPago == '1') && medioPago ? cuponTrue ? precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotalDescuento + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : cuponTrue ? precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotal + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio))));
        formData.append('banco', comprobante.banco);
        formData.append('fecha', comprobante.fecha);
        formData.append('idarea', 4704);
        const response = await axios.post('https://sistema.depcsuite.com/api/subir-comprobante', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.data.message) {
            localStorage.setItem('idpago', response.data.message);
        }else{
            setLoadingBuy(false)
        }
    };

    const handleSeleccionarOpcion = (opcion) => {
        setMedioPago(opcion);
    };

    const onClickCupon = async () => {
        const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/agregar-cupon', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, cuponNombre: (cupon || '') }),
            isBase64Encoded: false,
        });
        const data = JSON.parse(response.data.body);
        if (data.message) {
            setCuponTrue(true);
            setCuponProductos(data.productos);
        } else {
            setErrorCupon(true);
        }
    }

    const handleMercadoPago = async () => {
        const response = await axios.post('https://sistema.depcsuite.com/api/procesarCheckout', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({
                carrito,
                envio: (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio),
                dni: domicilio?.documento_personal,
                cuponProductos: cupon ? cuponProductos : [],
                dolar,
                idarea: 4704,
                idCLiente: user,
                cupon: cuponTrue,
                fk_idprov: domicilio?.fk_idprov,
                localidad: domicilio?.fk_idlocalidad,
                telefono: domicilio?.numero_celular,
                direccion: domicilio?.direccion,
                nota: domicilio?.descripcion,
                entre_calles: domicilio?.entre_calles,
                codpost: domicilio?.codpost,
                retiro_en_sucursal: (retiro == 'Envía a domicilio' ? 0 : 1),
                sucursal: (retiro == 'Envía a domicilio' ? 42 : 43),
                domicilioNew: domicilio.domicilioNew,
                comentario: 'sin comentario',
            }),
            isBase64Encoded: false,
        });
        localStorage.setItem('total', Number(((medioPago == '1') && medioPago ? cuponTrue ? precioTotalDescuento - precioCuponRestante : precioTotalDescuento + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : cuponTrue ? precioTotal - precioCuponRestante : precioTotal + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio))))
        localStorage.setItem('totalPrecio', precioTotal);
        trackEvent('InitiateCheckout', {
            content_ids: carrito.map(item => String(item.idproducto)), // IDs de productos en el carrito
            content_type: 'product', // Tipo de contenido
            value: precioTotal, // Valor total del carrito
            currency: 'ARS' // Moneda
        });
        let url = response.data.api;
        setCarritoYRedirigir(carrito, url);
    }

    const precioTotalDescuento = carrito.reduce((previous, current) => {
        const itemPriceUSDOffert = current.precio_oferta_dolares;
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = itemPriceUSD * precioDolar;
        const precioConIncrementoOferta = itemPriceUSDOffert * precioDolar;
    
        const porcentajePromo = Number(current.porcentaje_promo) || 0;
        const cantidadPromo = Number(current.cantidad_promo) || 0;
    
        let precioFinal, precioFinalOferta;
    
        if (porcentajePromo) {
            precioFinal = precioConIncremento * (1 - porcentajePromo / 100);
            precioFinalOferta = precioConIncrementoOferta * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            precioFinal = precioConIncremento - cantidadPromo;
            precioFinalOferta = precioConIncrementoOferta - cantidadPromo;
        } else {
            precioFinal = precioConIncremento;
            precioFinalOferta = precioConIncrementoOferta;
        }
    
        let precioConDescuento, precioConDescuentoOferta;
    
        if (porcentajePromo || cantidadPromo) {
            precioConDescuento = (precioFinal * 0.8) * current.cantidad;
            precioConDescuentoOferta = (precioFinalOferta * 0.8) * current.cantidad;
        } else {
            precioConDescuento = (precioFinal * 0.8) * current.cantidad;
            precioConDescuentoOferta = (precioFinalOferta * 0.8) * current.cantidad;
        }
        return previous + (Number(itemPriceUSDOffert) !== 0 ? Number(precioConDescuentoOferta.toFixed(0)) : Number(precioConDescuento.toFixed(0)));
    }, 0);    
    

    const precioTotalCupon = carrito.reduce((previous, current) => {
        if (cuponProductos && Array.isArray(cuponProductos)) {
            const existProduct = cuponProductos.find(producto => producto.idproducto == current.idproducto);
            const priceProduct = existProduct ? existProduct.descuento : 0;
            if (existProduct) {
                const itemPriceUSD = current.precio_dolar;
                const precioConIncremento = itemPriceUSD * precioDolar;

                const descuento = precioConIncremento * (priceProduct / 100);

                const precioFinal = ((precioConIncremento - descuento) * current.cantidad).toFixed(0);

                return previous + Number(precioFinal);
            } else {
                const itemPriceUSD = current.precio_dolar;
                const precioConIncremento = itemPriceUSD * precioDolar;
                const precioFinal = (precioConIncremento * current.cantidad).toFixed(0);
                return previous + Number(precioFinal);
            }
        }
    }, 0);
    const precioTotalCuponConOferta = carrito.reduce((previous, current) => {
        if (cuponProductos && Array.isArray(cuponProductos)) {
            const existProduct = cuponProductos.find(producto => producto.idproducto == current.idproducto);
            const priceProduct = existProduct ? existProduct.descuento : 0;
            if (existProduct) {
                const itemPriceUSD = current.precio_oferta_dolares;
                const precioConIncremento = itemPriceUSD * precioDolar;

                const descuento = precioConIncremento * (priceProduct / 100);

                const precioFinal = ((precioConIncremento - descuento) * current.cantidad).toFixed(0);

                return previous + Number(precioFinal);
            } else {
                const itemPriceUSD = current.precio_oferta_dolares;
                const precioConIncremento = itemPriceUSD * precioDolar;
                const precioFinal = (precioConIncremento * current.cantidad).toFixed(0);
                return previous + Number(precioFinal);
            }
        }
    }, 0);

    const precioTotalOferta = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSDOffert = current.precio_oferta_dolares;
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = itemPriceUSD * precioDolar;
        const precioConIncrementoOferta = itemPriceUSDOffert * precioDolar;

        const descuento = precioConIncremento * 0;
        const descuentoOferta = precioConIncrementoOferta * 0;

        const precioFinal = ((precioConIncremento - descuento) * current.cantidad).toFixed(0);
        const precioFinalOferta = ((precioConIncrementoOferta - descuentoOferta) * current.cantidad).toFixed(0);
        return previous + (Number(itemPriceUSDOffert) != 0 ? Number(precioFinalOferta) : Number(precioFinal));
    }, 0);

    const precioTotal = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = itemPriceUSD * precioDolar;
        
        // Definimos porcentaje y cantidad de la promoción
        const porcentajePromo = Number(current.porcentaje_promo) || 0;
        const cantidadPromo = Number(current.cantidad_promo) || 0;
      
        let resultado;
        if (porcentajePromo) {
            resultado = precioConIncremento * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            resultado = precioConIncremento - cantidadPromo;
        } else {
            resultado = precioConIncremento; // Si no hay promo, se toma el precio con incremento directamente
        }
        
        // Aplicamos un descuento adicional del 20%
        const precioConDescuentoAdicional = resultado;
      
        // Calculamos el precio final por la cantidad de artículos
        const precioFinal = (precioConDescuentoAdicional * current.cantidad).toFixed(0);
    
        // Sumar el precio final al total acumulado
        return previous + Number(precioFinal);
    }, 0);
    

    const precioCuponRestante = Number(((medioPago == '1') && medioPago ? (precioTotalCuponConOferta - precioTotalDescuento) : (precioTotal - precioTotalCupon)));

    const diferencialConPorcentaje = precioTotalDescuento - precioTotal;
    const totalEnvio = precioTotalOferta;

    const mapaLocalidades = {};
    localidades?.forEach(localidad => {
        mapaLocalidades[localidad.idlocalidad] = localidad.nombre;
    });

    const mapaProvincias = {};

    provincias?.forEach(provincia => {
        mapaProvincias[provincia.idprov] = provincia.descprov;
    });

    return (
        <div>
            <div className='lg:hidden mx-2'>
                <div className='flex bg-[#f9f9fb] ml-1 pt-3 pb-3 mt-5 px-4 flex-row justify-between'>
                    <div className='flex flex-row'>
                        <img src={cartImg} className='w-4' alt="" />
                        <p className='text-left text-sm mt-1 font-semibold ml-1 text-[#E40F1B]'>Mi carrito: {Number(((medioPago == '1') && medioPago ? cuponTrue ? precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotalDescuento + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : cuponTrue ? precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotal + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio))).toLocaleString('es-AR', {
                            style: 'currency',
                            currency: 'ARS',
                            maximumFractionDigits: 0,
                        })}</p>
                    </div>
                    <div>
                        {
                            isOpen ?
                                <button onClick={() => setIsOpen(false)}>
                                    <img src={minus} alt='cerrar' />
                                </button>
                                :
                                <button onClick={() => setIsOpen(true)}>
                                    <img src={plus} alt='abrir' />
                                </button>
                        }
                    </div>
                </div>
                {isOpen && <div className='bg-[#f9f9fb] h-auto ml-1 px-4 pb-4 pt-2'>
                    <div className='space-y-2'>
                        <div>
                            {
                                carrito && loading ? carrito.map((item) => (
                                    <div>
                                        {Number(item.precio_dolar) !== 0 &&
                                            <div>
                                                <CardDatos precio={medioPago} dolar={precioDolar} item={item} setLoading={setLoading} />
                                                <div className='border-b-2 my-2 border-opacity-30'></div>
                                            </div>
                                        }
                                    </div>
                                ))
                                    :
                                    <div className="flex justify-center items-center mt-32 mb-60">
                                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='flex flex-col items-center my-4'>
                        <div className="flex flex-row">
                            <input onChange={(e) => setCupon(e.target.value)} className='border pl-1 py-2 rounded-sm hover:border-[#E40F1B] flex-grow mr-3' placeholder='Tengo un cupón de descuento' type="text" />
                            <button onClick={() => onClickCupon()} className='border hover:bg-gray-500 hover:text-white border-gray-500 w-1/4 rounded-sm'>APLICAR</button>
                        </div>
                        {errorCupon && <p className='my-2 text-sm text-[#E40F1B] text-left'>El cupon no existe o ya ha sido utilizado</p>}
                    </div>
                    <div>
                        {cuponTrue &&
                            <div>
                                <div className='my-2'></div>
                                <div className='flex justify-between'>
                                    <p className='text-left'>Cupon 15% OFF</p>
                                    <p className='text-right'>{Number(precioCuponRestante).toLocaleString('es-AR', {
                                        style: 'currency',
                                        currency: 'ARS',
                                        maximumFractionDigits: 0,
                                    })}</p>
                                </div>
                            </div>
                        }
                        <div className='flex justify-between font-semibold my-2'>
                            <p>Subtotal</p>
                            <p>{Number(precioTotal).toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                maximumFractionDigits: 0,
                            })}</p>
                        </div>
                        {envio && <div>
                            <div className='border-b-2 my-2'></div>
                            <div className='flex justify-between my-2'>
                                <p>Envío</p>
                                {Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ?
                                    <p>GRATIS</p>
                                    :
                                    <p>+{Number(envio).toLocaleString('es-AR', {
                                        style: 'currency',
                                        currency: 'ARS',
                                        maximumFractionDigits: 0,
                                    })}</p>
                                }
                            </div>
                        </div>}
                        {(medioPago === '1') && <div className='border-b-2 my-2'></div>}
                        {(medioPago === '1') &&
                            <div className='flex justify-between'>
                                <p className='text-left'>Descuento por pago con transferencia:</p>
                                <p className='text-right'>{Number(diferencialConPorcentaje).toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    maximumFractionDigits: 0,
                                })}</p>
                            </div>}
                        <div className='border-b-2 my-2'></div>
                        <div className='flex justify-between font-semibold my-2'>
                            <p>TOTAL</p>
                            <p>{Number(((medioPago == '1') && medioPago ? cuponTrue ? precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotalDescuento + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : cuponTrue ? precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotal + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio))).toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                maximumFractionDigits: 0,
                            })}</p>
                        </div>
                        <div className='border-b-2 my-2'></div>
                        <p className='text-right text-sm opacity-70'>Precio en pesos argentinos. Incluye impuestos.</p>
                    </div>
                </div>}

            </div>
            <div className='mt-5 lg:mx-10 mx-3 lg:grid flex flex-col lg:grid-cols-2 mb-20'>
                {errorQuery && errorPago &&
                    <div className="relative top-72">
                        <div onClick={() => setErrorPago(false)} className="fixed inset-0 bg-black bg-opacity-50 z-40" aria-hidden="true"></div>
                        <div className='fixed left-1/2 z-[300000]'>
                            <div className="fixed top-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-[300px] w-1/3 bg-white border-2 border-gray-300 shadow-lg z-50 rounded-lg p-6">
                                <div className="flex flex-col items-start">
                                    <div className="shrink-0">
                                        <div className="flex items-center ml-40 mb-6 justify-center h-12 w-12 rounded-full bg-red-100">
                                            <svg className="h-6 w-6 text-[#E40F1B]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                            Tu pago no pudo ser procesado, por favor intenta con otro medio de pago.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="lg:mr-8">
                    <div className="border border-[#E40F1B] rounded-sm">
                        <div className="flex flex-row text-left mx-2 border-b-[0.5px] py-2 pl-2">
                            <p className="w-16">Contacto: </p>
                            <p className="font-semibold ml-5">{retiro !== 'Retiro al local' ? domicilio?.numero_celular : domicilio?.documento_personal}</p>
                        </div>
                        {retiro !== 'Retiro al local' && <div className="flex flex-row text-left mx-2 border-b-[0.5px] py-2 pl-2">
                            <p className="w-16 whitespace-nowrap">Enviar a:</p>
                            <p className="font-semibold ml-5">{`${mapaProvincias[domicilio.fk_idprov]}, ${mapaLocalidades[domicilio.fk_idlocalidad]}, ${domicilio?.direccion}, ${domicilio?.piso && 'Piso'} ${domicilio?.piso} ${domicilio?.dpto && 'dpto'} ${domicilio?.dpto}, ${domicilio?.codpost} `}</p>
                        </div>}
                        <div className="flex flex-row text-left mx-2 py-2 pl-2">
                            <p className="w-16">Método:</p>
                            <p className="font-semibold ml-5">{retiro === 'Retiro al local' ? 'Retiro al local' : envio == 0 ? 'Envío a domicilio - A cargo del cliente' : Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 'Envío a domicilio - GRATIS' : `Envío a domicilio (no incluye subida por escalera) - ${Number(envio).toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                maximumFractionDigits: 0,
                            })}.-`}</p>
                        </div>
                    </div>
                    {!pass ?
                        <div>
                            <p className="text-left text-lg mt-16 font-semibold">Forma de pago</p>
                            <p className="text-left mt-2">Todas las transacciones son seguras y están encriptadas.</p>
                        </div>
                        :
                        <div>
                            <p className="text-center text-lg mt-16 font-semibold">Tu compra ha sido exitosa</p>
                        </div>
                    }
                    {!pass && <div className='grid grid-cols-2 mt-5'>
                        <button
                            className={`border mr-2 rounded-sm text-sm h-16 items-center flex flex-row ${medioPago === '20' ? 'bg-gray-200' : ''
                                }`}
                            onClick={() => handleSeleccionarOpcion('20')}
                        >
                            <input
                                type="radio"
                                name="opcion"
                                className='ml-2'
                                id="20"
                                checked={medioPago === '20'}
                                onChange={() => handleSeleccionarOpcion('20')}
                            />
                            <p className='ml-2'>Pago con Mercado Pago</p>
                        </button>
                        <button
                            className={`border ml-2 rounded-sm text-sm h-16 items-center flex flex-row ${medioPago === '1' ? 'bg-gray-200' : ''
                                }`}
                            onClick={() => handleSeleccionarOpcion('1')}
                        >
                            <input
                                type="radio"
                                name="opcion"
                                className='ml-2'
                                id="1"
                                checked={medioPago === '1'}
                                onChange={() => handleSeleccionarOpcion('1')}
                            />
                            <p className='ml-2'>Transferencia (20% OFF)</p>
                        </button>
                    </div>}
                    {!pass && <button
                        className={`border mr-2 mt-5 w-2/4 lg:ml-32 ml-20 rounded-sm text-sm h-16 items-center flex flex-row ${medioPago === '25' ? 'bg-gray-200' : ''
                            }`}
                        onClick={() => handleSeleccionarOpcion('25')}
                    >
                        <input
                            type="radio"
                            name="opcion"
                            className='ml-2'
                            id="25"
                            checked={medioPago === '25'}
                            onChange={() => handleSeleccionarOpcion('25')}
                        />
                        <p className='ml-2'>Pago con MODO</p>
                    </button>}
                    {medioPago === '1' &&
                        <div>
                            <h3 className="text-left text-lg mt-16 font-semibold">1) Realizar la transferencia bancaria a la siguiente cuenta:</h3>
                            <div className="bg-[#f9f9fb] flex flex-col justify-center items-center h-[30%] pt-10">
                                <p className="lg:px-20 px-5 text-sm opacity-75">
                                    Los siguientes son los datos para transferencia bancaria. Cuando realices la transferencia ingresá tu DNI como referencia de la misma. Una vez realizado envianos el comprobante a hola@sentate-conestilo.com
                                </p>
                                <div className="bg-white border text-[13px] border-[#F17470] mb-10 lg:w-1/2 lg:px-0 w-5/6 mt-3">
                                    <p className="mb-2">Nuestros detalles bancarios </p>
                                    <p className="text-center">TITULAR: ESTILO 1 + 1 SAS <br />CUIT: 30716170299 <br />CUENTA CORRIENTE: 9215-9 064-1 <br />CBU: 00700641-20000009215913 <br />ALIAS: ESTILO.1.1-SAS <br />BANCO: GALICIA</p>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-left text-lg mt-16 font-semibold">2) Completa los siguientes datos de transferencia:</h3>
                                <div className="grid grid-cols-2 gap-4 mt-5 text-left text-sm">
                                    <div className="w-full">
                                        <p className="">Fecha de transferencia:</p>
                                        <input required type="date" name="fecha" onChange={handleChangeInput} className={`w-full ${formErrors.fecha ? 'border-red-500' : ''} pl-2 h-[40px] border`} />
                                    </div>
                                    <div className="w-full mb-4">
                                        <label htmlFor="banco" className="block text-gray-700">Banco</label>
                                        <Select
                                            name="banco"
                                            value={selectedBank}
                                            onChange={handleChangeSelect}
                                            options={options}
                                            classNamePrefix="react-select"
                                            className={`mt-1 ${formErrors.banco ? 'border-red-500' : 'border-gray-300'}`}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderColor: formErrors.banco ? '#f44336' : provided.borderColor,
                                                    '&:hover': {
                                                        borderColor: formErrors.banco ? '#f44336' : provided.borderColor,
                                                    },
                                                    boxShadow: state.isFocused
                                                        ? formErrors.banco
                                                            ? '0 0 0 1px #f44336'
                                                            : '0 0 0 1px #2684FF'
                                                        : provided.boxShadow,
                                                }),
                                            }}
                                        />
                                        {formErrors.banco && (
                                            <span className="text-red-500 text-sm mt-1">
                                                {formErrors.banco}
                                            </span>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <p>Importe transferido:</p>
                                        <input required type="number" defaultValue={Number(((medioPago == '1') && medioPago ? cuponTrue ? precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotalDescuento + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : cuponTrue ? precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotal + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio)))} name="importe" onChange={handleChangeInput} className={`w-full pl-2 h-[40px] border ${formErrors.importe ? 'border-red-500' : ''}`} />
                                    </div>
                                    <div className="w-full">
                                        <p>Adjuntar comprobante:</p>
                                        <input required type="file" name="txtArchivoDocumento" onChange={handleChangeInput} className={`w-full pl-2 h-[40px] border ${formErrors.txtArchivoDocumento ? 'border-red-500' : ''}`} />
                                    </div>
                                </div>
                            </div>
                            <h3 className="text-left text-lg mt-16 font-semibold">3) Haz clic en el botón "Confirmar pago":</h3>
                        </div>
                    }
                    {medioPago === '25' &&
                        <div className="my-16 flex flex-col lg:ml-[20%]">
                            <p className="flex flex-row text-sm">1. Abrí tu app de preferencia para pagar con <p className="ml-1 font-semibold text-[#E40F1B]">Modo</p></p>
                            <p className="flex flex-row text-sm">2. Escanea el <p className="font-semibold mx-1 text-[#E40F1B]">QR</p> e ingresá el importe a pagar</p>
                            <div className="">
                                <img src={modoQr} alt="" className="ml-10 mt-5 w-60" />
                            </div>
                            <p className="flex flex-row text-sm">3. Haz clic en<p className="font-semibold ml-1 text-[#E40F1B]">finalizar compra</p></p>
                        </div>
                    }
                    {medioPago === '20' && !pass &&
                        <div className="my-16 flex flex-col text-center items-center">
                            <p className="flex flex-row text-sm">Realiza tu compra mediante <p className="font-semibold mx-1 text-[#E40F1B]">MercadoPago</p></p>
                            <button onClick={handleMercadoPago} className="my-7 border text-white bg-[#E40F1B] w-2/3 hover:bg-red-400 rounded-sm hover:text-black py-1">
                                Pagar con MercadoPago
                            </button>
                            <p className="flex flex-col text-sm">Una vez realizada la compra envianos el comprobante a <p className="font-semibold mx-1 text-[#E40F1B]">hola@sentate-conestilo.com </p></p>
                        </div>

                    }
                    {medioPago === '20' && pass &&
                        <div className="my-16 flex flex-col text-center items-center">
                            <p className="flex flex-row text-sm">Pagado con<p className="font-semibold mx-1 text-[#E40F1B]">MercadoPago</p></p>
                            <p className="flex flex-col text-sm">Recuerda enviar el comprobante a <p className="font-semibold mx-1 text-[#E40F1B]">hola@sentate-conestilo.com </p></p>
                        </div>
                    }
                    <div className='flex lg:justify-between lg:flex-row flex-col-reverse mt-20'>
                        <button onClick={() => setStep((step - 1))} className='opacity-70'>Cancelar</button>
                        {medioPago !== '20' && <button onClick={() => {
                            if(!loadingBuy){
                                handleSubmit();
                            }
                        }} className='bg-[#1c1c1c] text-white px-16 lg-pt-0 mb-4 lg:h-10 h-14 py-1 uppercase hover:bg-[#E40F1B]'
                        >
                            {loadingBuy ? <><svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg></> : 'Confirmar pago'}
                        </button>}
                    </div>
                </div>
                <div className='bg-[#f9f9fb] lg:block hidden mt-10 max-h-1/2 ml-1'>
                    <div className='mx-4'>
                        <p className='uppercase text-left text-lg font-semibold mt-2 '>Mi compra</p>
                        <div>
                            {
                                carrito && loading ? carrito.map((item) => (
                                    <div>
                                        {Number(item.precio_dolar) !== 0 &&
                                            <div>
                                                <CardDatos precio={medioPago} dolar={precioDolar} item={item} setLoading={setLoading} />
                                                <div className='border-b-2 my-2 border-opacity-30'></div>
                                            </div>
                                        }
                                    </div>
                                ))
                                    :
                                    <div className="flex justify-center items-center mt-32 mb-60">
                                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                            }
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-row'>
                                <input onChange={(e) => setCupon(e.target.value)} className='border pl-1 py-2 rounded-sm hover:border-[#E40F1B] mr-3 w-2/3' placeholder='Tengo un cupón de descuento' type="text" />
                                <button onClick={() => onClickCupon()} className='border hover:bg-gray-500 hover:text-white border-gray-500 w-1/3 rounded-sm' >APLICAR</button>
                            </div>
                            {errorCupon && <p className='my-2 text-sm text-[#E40F1B] text-left'>El cupon no existe o ya ha sido utilizado</p>}
                        </div>
                        <div>
                            {precioTotal !== 0 &&
                                <div>
                                    <div className='my-2'></div>
                                    <div className='flex justify-between font-semibold'>
                                        <p className='text-left'>Subtotal</p>
                                        <p className='text-right'>{Number(precioTotal).toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS',
                                            maximumFractionDigits: 0,
                                        })}</p>
                                    </div>
                                </div>
                            }
                            {envio !== 0 && <div>
                                <div className='border-b-2 my-2'></div>
                                <div className='flex justify-between my-2'>
                                    <p>Envío</p>
                                    {Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ?
                                        <p>GRATIS</p>
                                        :
                                        <p>+{Number(envio).toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS',
                                            maximumFractionDigits: 0,
                                        })}</p>
                                    }
                                </div>
                            </div>}
                            {(medioPago === '1') && <div className='border-b-2 my-2'></div>}
                            {(medioPago === '1') &&
                                <div className='flex justify-between'>
                                    <p className='text-left'>Descuento por pago con transferencia:</p>
                                    <p className='text-right'>{Number(diferencialConPorcentaje).toLocaleString('es-AR', {
                                        style: 'currency',
                                        currency: 'ARS',
                                        maximumFractionDigits: 0,
                                    })}</p>
                                </div>}
                            {cuponTrue &&
                                <div>
                                    <div className='my-2 border-b-2'></div>
                                    <div className='flex justify-between'>
                                        <p className='text-left'>Cupon: 15% OFF</p>
                                        <p className='text-right'>-{Number(precioCuponRestante).toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS',
                                            maximumFractionDigits: 0,
                                        })}</p>
                                    </div>
                                </div>
                            }
                            <div className='border-b-2 my-2'></div>
                            <div className='flex justify-between'>
                                <p className='text-left'>TOTAL</p>
                                <p className='text-right'>{Number(((medioPago == '1') && medioPago ? cuponTrue ? precioTotalDescuento - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotalDescuento + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : cuponTrue ? precioTotal - precioCuponRestante + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio) : precioTotal + (Number(precioTotalDescuento) > 200000 && domicilio?.fk_idprov == "2" ? 0 : envio))).toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    maximumFractionDigits: 0,
                                })}</p>
                            </div>
                            <div className='border-b-2 my-2'></div>
                            <p className='text-right text-sm opacity-70'>Precio en pesos argentinos. Incluye impuestos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}