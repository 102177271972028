import axios from "axios";
import React, { useEffect, useState } from "react";
import fav from '../../../assets/favorite.svg';
import disenamos from '../../../assets/_disenos.svg';
import embalaje from '../../../assets/__embalaje_1.svg';
import cuotas from '../../../assets/_cuotas.svg';
import envios from '../../../assets/_envios.svg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from "react-redux";
import { getCarrito } from "../../../redux/actions";
import { trackEvent } from "../../../utils/fbpPixel";

function Pago({ cuponTrue, cuponProductos }) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idPedido = searchParams.get('idpedido');
    const cupones = searchParams.get('cupon') || cuponTrue;
    const [isRequestSent, setRequestSent] = useState(false);
    const carritoSinFiltro = useSelector((state) => state.carrito);
    const carrito = carritoSinFiltro.length !== 0 ? carritoSinFiltro.filter((item) => Number(item.precio_dolar) !== 0) : JSON.parse(localStorage.getItem('carrito'));
    const dolar = localStorage.getItem('dolar');
    const totalPrecio = localStorage.getItem('totalPrecio');
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const pago = searchParams.get('pago');
    const [localidades, setLocalidades] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [medioPago, setMedioPago] = useState('');
    const [medioPagoEmail, setMedioPagoEmail] = useState('');
    const [actualizoIdPedido, setActualizoIdPedido] = useState('');
    const total = localStorage.getItem('total');
    const domicilio = JSON.parse(localStorage.getItem('domicilio'));
    const retiro = localStorage.getItem('personal');
    const idPago = localStorage.getItem('idpago');
    const mediopago = pago || localStorage.getItem('mediopago');
    const envio = Number(localStorage.getItem('envio'));

    const mailUsuario = localStorage.getItem('mail');
    const asuntoUsuario = "¡Recibimos tu pedido!";

    const mailCliente = 'hola@sentate-conestilo.com';
    const asuntoCliente = "Nuevo pedido en Sentate con Estilo";
    const dispatch = useDispatch();

    useEffect(() => {
        fetchProvincias();
        fetchLocalidades(domicilio?.fk_idprov);
        carrito && trackEvent('Purchase', {
            content_ids: carrito?.map(item => String(item.idproducto)), // IDs de productos en el carrito
            content_type: 'product', // Tipo de contenido
            value: total, // Valor total del carrito
            currency: 'ARS' // Moneda
        });
    }, [])

    const axiosPost = async (url, data) => {
        return await axios.post(url, {
            httpMethod: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            isBase64Encoded: false,
        });
    };

    const fetchLocalidades = async (fk_idprov) => {
        const response = await axiosPost('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', { fk_idprov });
        const localidadesData = JSON.parse(response?.data?.body);
        setLocalidades(localidadesData);
    };

    const fetchProvincias = async () => {
        const response = await fetch('https://api.depcsuite.com/td/getProvincias?id=12', {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        setProvincias(await response.json());
    };

    const mapaLocalidades = {};
    localidades?.forEach(localidad => {
        mapaLocalidades[localidad.idlocalidad] = localidad.nombre;
    });

    const mapaProvincias = {};

    provincias?.forEach(provincia => {
        mapaProvincias[provincia.idprov] = provincia.descprov;
    });

    const enviarCorreoUsuario = async (idPedidos, medioPagos) => {
        let contacto = `${domicilio?.nombre} ${domicilio?.apellido} / ${domicilio?.documento_personal}`;
        let direccionEnvio = '';
        let metodoEnvio = '';
        let notasPedido = '';

        if (retiro !== 'Retiro al local') {
            direccionEnvio = `<p><strong>Enviar a:</strong>${domicilio?.direccion}, ${domicilio?.codpost}${domicilio?.piso ? `, Piso ${domicilio?.piso}` : ''}${domicilio?.dpto ? `, dpto ${domicilio?.dpto}` : ''}</p>`;
            metodoEnvio = retiro !== 'Retiro al local' ? envio == 0 ? 'Envío a domicilio - A cargo del cliente' : Number(total) > 200000 && domicilio?.fk_idprov == 2 ? 'Envío a domicilio - GRATIS' : `Envío a domicilio (no incluye subida por escalera) - ${Number(envio).toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                maximumFractionDigits: 0,
            })}.-` : 'Retiro al local';
            notasPedido = `<p><strong>Notas de pedido:</strong> ${domicilio?.descripcion}</p>`;
        } else {
            metodoEnvio = 'Retiro al local';
        }
        const cuerpoCorreoUsuario = `
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; color: #333;">
                <div style="max-width: 600px; margin: 20px auto; background: #fff; padding: 20px; border: 1px solid #ddd;">
                    <div style="padding: 20px 0 40px;">
                        <img src="https://www.sentateconestilo.com.ar/static/media/logo-horizontal-ok-removebg-preview.fdff2ca1a78ac90d4ede.png" alt="Logo SCE"
                            style="width: 370px; height: auto;">
                    </div>
                    <h2 style="color: black;">¡Recibimos tu pedido!</h2>
                    <p style="font-weight: bold;">Número de pedido: #${idPedidos}</p>

                    <div style="background: #eeeeee; padding: 10px; margin-bottom: 20px;">
                        <h3 style="color: black;">Detalle de tu pedido</h3>
                        <p><strong>Contacto:</strong> ${contacto}</p>
                        ${direccionEnvio}
                        <p><strong>Método de envío:</strong> ${metodoEnvio}</p>
                        <p><strong>TOTAL:</strong> ${Number(total)?.toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            maximumFractionDigits: 0,
                        })}.-</p>
                        <p><strong>Medio de pago:</strong> ${medioPagos}</p>
                        ${notasPedido}
                    </div>
            <h3 style="color: black;">Completa el pago realizando la transferencia a:</h3>
            <div style="margin: 0; padding: 0; background-color: #f4f4f4; font-family: Arial, sans-serif;">
                <div style="max-width: 600px; margin: 20px auto; padding: 20px;">
                    <p style="font-size: 16px; color: #555;">
                        Los siguientes son los datos para transferencia bancaria. Cuando realices la transferencia ingresa
                        tu DNI como referencia de la misma. Una vez realizado envíanos el comprobante a
                        <a href="mailto:hola@sentate-conestilo.com"
                            style="color: black; text-decoration: none;">hola@sentate-conestilo.com</a>
                    </p>
                    <div
                        style="background-color: #ffffff; padding: 20px; border: 1px solid black; margin-top: 10px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
                        <h4 style="color: black; border-bottom: 1px solid black; padding-bottom: 5px; margin-bottom: 10px;">
                            Nuestros detalles bancarios
                        </h4>
                        <p style="font-size: 14px; color: #555; margin: 0;">
                            <strong>TITULAR:</strong> ESTILO 1 + 1 SAS<br />
                            <strong>CUIT:</strong> 30716170299<br />
                            <strong>CUENTA CORRIENTE:</strong> 9215-9 064-1<br />
                            <strong>CBU:</strong> 00700641-20000009215913<br />
                            <strong>ALIAS:</strong> ESTILO.1.1-SAS<br />
                            <strong>BANCO:</strong> GALICIA
                        </p>
                    </div>
                </div>
            </div>

                    <div style="opacity: 70%; font-size: 14px; color: #777777; border-top: 1px solid #dddddd; 
                                    padding-top: 20px;">
                        Por cualquier consulta podés responder este correo electrónico o contactarnos a través de
                        <a href="mailto:hola@sentate-conestilo.com" style="color: #000000; text-decoration: none;">
                            hola@sentate-conestilo.com
                        </a>
                        o por
                        <a href="https://api.whatsapp.com/send?phone=541132619277" style="color: #000000; text-decoration: none;">
                            WhatsApp
                        </a>
                    </div>
                </div>
            </body>
        `
        await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
            params: {
                asunto: asuntoUsuario,
                correo: mailUsuario,
                CuerpoCorreo: cuerpoCorreoUsuario
            },
            headers: {
                "Content-Type": "application/json"
            }
        });
    }


    useEffect(() => {
        switch (mediopago) {
            case '1':
                setMedioPago('Transferencia Bancaria');
                setMedioPagoEmail('Transferencia Bancaria');
                break;
            case '20':
                setMedioPago('MercadoPago');
                setMedioPagoEmail('MercadoPago');
                break;
            case '25':
                setMedioPago('Transferencia por MODO')
                setMedioPagoEmail('Transferencia por MODO')
                break;
            default:
                break;
        }
        const deleteCarrito = async () => {
            await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/delete-carrito', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ token: token }),
                isBase64Encoded: false,
            });
            localStorage.removeItem('carrito');
        }
        const updateStock = async () => {
            await axios.post('https://5fmuobow2m.execute-api.us-east-1.amazonaws.com/prod/actualizar-stock', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ carrito }),
                isBase64Encoded: false,
            });
        }

        const enviarCorreoCliente = async (idPedidos) => {
            const cuerpoCorreoCliente = `
                <body>
                    <p>Hola, hubo un nuevo pedido en SCE:</p>
                    <ul>
                        <div style="background: #eeeeee; padding: 5px; margin-bottom: 20px;">
                            <h3 style="color: black;">Detalle del pedido</h3>
                            <p>Tenés una nueva venta en la web.</p>
                            <p><strong>Número de pedido:</strong> ${idPedidos}</p>    
                            <p>https://sistema.depcsuite.com/administracion/pedidos</p>
                        </div>
                    </ul>
                    <p>Saludos!</p>
                </body>`
            await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
                params: {
                    asunto: asuntoCliente,
                    correo: mailCliente,
                    CuerpoCorreo: cuerpoCorreoCliente
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
        }
        const insertarPedido = async () => {
            dispatch(getCarrito(token));
            if (!searchParams.get('idpedido')) {
                const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/insertar-pedido', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ carrito, token, dolar, medioPago: mediopago, cupones, envio, envioSucursal: retiro, domicilioNew: 'new', cuponProductos: cuponTrue ? cuponProductos : [], idPago }),
                    isBase64Encoded: false,
                });
                if (response.data.statusCode === 200) {
                    const body = JSON.parse(response.data.body)
                    setRequestSent(true);
                    await updateStock();
                    await deleteCarrito();
                    localStorage.removeItem('idpago');
                    const esTranferencia = window.localStorage.getItem('mediopago') == 1 ? 'Transferencia Bancaria' : null;
                    const esModo = window.localStorage.getItem('mediopago') == 25 ? 'Transferencia por MODO' : null;
                    const esMP = !esTranferencia && !esModo ? true : false;
                    if (!esMP) {
                        await enviarCorreoCliente(idPedido || body.idpedido, esTranferencia || esModo);
                        await enviarCorreoUsuario(idPedido || body.idpedido, esTranferencia || esModo);
                    }
                    setActualizoIdPedido(body.idpedido);
                }
            } else {
                await updateStock();
                setActualizoIdPedido(idPedido)
            }
        }
        insertarPedido();
    }, []);
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();

    const currentDateFormatted = `${day}/${month}/${year}`;

    return (
        <div className="text-left lg:ml-10 mx-2 mt-8">
            <p className="font-semibold text-[25px]">¡Recibimos tu pedido!</p>
            <p className="mt-6 mb-3 text-xl flex flex-row">Número de pedido: <p className="font-semibold ml-1">#{idPedido || actualizoIdPedido}</p></p>
            <p className="mt-6 mb-3 font-semibold text-xl">Detalle de tu pedido</p>
            <div className="border lg:w-[627px] text-sm border-[#E40F1B]">
                <div className="flex text-lg border-b-[0.5px] my-1 mx-5">
                    <p className="lg:w-36 w-2/6">Contacto:</p>
                    <p className=" w-4/6 lg:ml-5">{`${domicilio?.nombre} ${domicilio?.apellido} / ${domicilio?.documento_personal}`}</p>
                </div>
                {retiro !== 'Retiro al local' && <div className="flex text-lg border-b-[0.5px] my-1 mx-5">
                    <p className="lg:w-36 w-2/6">Enviar a:</p>
                    <p className=" w-4/6 lg:ml-5">{`${mapaProvincias[domicilio.fk_idprov]}, ${mapaLocalidades[domicilio.fk_idlocalidad]}, ${domicilio?.direccion}, ${domicilio?.codpost}, ${domicilio?.piso && 'Piso'} ${domicilio?.piso} ${domicilio?.dpto && 'dpto'} ${domicilio?.dpto} `}</p>
                </div>}
                <div className="flex text-lg border-b-[0.5px] my-1 mx-5">
                    <p className="lg:w-36 w-2/6">Método de envío:</p>
                    <p className=" w-4/6 lg:ml-5">{retiro !== 'Retiro al local' ? envio == 0 ? 'Envío a domicilio - A cargo del cliente' : Number(total) > 200000 && domicilio?.fk_idprov == 2 ? 'Envío a domicilio - GRATIS' : `Envío a domicilio (no incluye subida por escalera) - ${Number(envio).toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        maximumFractionDigits: 0,
                    })}.-` : 'Retiro al local'}</p>
                </div>
                <div className="flex text-lg border-b-[0.5px] my-1 mx-5">
                    <p className="lg:w-36 w-2/6">TOTAL</p>
                    <p className=" w-4/6 lg:ml-5">{Number(total)?.toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        maximumFractionDigits: 0,
                    })}.-</p>
                </div>
                <div className="flex border-b-[0.5px] text-lg my-1 mx-5">
                    <p className="lg:w-36 w-2/6">Medio de pago:</p>
                    <p className=" lg:ml-5 w-4/6">{`${medioPago}`}</p>
                </div>
                {retiro !== 'Retiro al local' && <div className="flex text-lg my-1 mx-5">
                    <p className="lg:w-36 w-2/6">Notas de pedido:</p>
                    <p className=" lg:ml-5 w-4/6">{domicilio?.descripcion}</p>
                </div>}
            </div>
            <p className="mt-14 text-center lg:text-left text-[#E40F1B] font-semibold uppercase">Importante:</p>
            <p className="lg:w-1/2 text-center lg:text-left text-[#E40F1B] ">Recordá que la entrega de los productos se realiza en la puerta del domicilio. El envío no incluye subida por escaleras ni armado de los productos.</p>
            <div className="flex flex-row overflow-x-auto lg:ml-0 mt-12">
                <div className="flex flex-col mt-6">
                    <p className="font-semibold mt-3 mb-4">Estado de tu pedido</p>
                    <div className="absolute border lg:w-[90%] w-full -z-10 mt-10 border-b-2 border-[#E40F1B]"></div>
                    <p className="text-sm w-60">Te notificaremos al mail cuando tu pedido cambie de estado. También podés hacer el seguimiento en "Mi Perfil".</p>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col ml-20 items-center">
                        <p className={`text-[10px] lg:mt-5 mt-3 font-semibold`}>Pedido recibido</p>
                        <img src={disenamos} className="w-10 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                        <p className={`text-[10px] font-semibold`}>{currentDateFormatted}</p>
                    </div>
                    <div className="flex flex-col lg:mt-5 mt-3 ml-20 items-center">
                        <p className={`text-[10px]  ${mediopago !== 20 ? 'opacity-70' : ''} font-semibold`}>Pago pendiente</p>
                        <img src={cuotas} className="w-10 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                        <p className={`text-[10px]  ${mediopago !== 20 ? 'opacity-70' : ''} font-semibold`}>{mediopago !== 20 ? '--/--/----' : currentDateFormatted}</p>
                    </div>
                    <div className="flex flex-col ml-20 items-center">
                        <p className={`text-[10px] opacity-70 font-semibold mt-1 w-40 text-center`}>Pedido pendiente de preparación</p>
                        <img src={embalaje} className="w-10 mt-3 mb-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                        <p className={`text-[10px]  opacity-70 font-semibold`}>--/--/----</p>
                    </div>
                    <div className="flex flex-col lg:mt-5 mt-3 ml-20 items-center">
                        <p className="text-[10px] font-semibold opacity-70">Envío pendiente</p>
                        <img src={envios} className="w-10 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                        <p className="text-[10px] font-semibold opacity-70">--/--/----</p>
                    </div>
                    <div className="flex flex-col lg:mt-5 mt-3 ml-20 items-center">
                        <p className="text-[10px] font-semibold opacity-70">Entrega estimada</p>
                        <img src={fav} className="w-10 p-2 bg-gray-100 my-2 border-[2px] object-cover border-[#E40F1B] rounded-full" alt="" />
                        <p className="text-[10px] font-semibold opacity-70">--/--/----</p>
                    </div>
                </div>
            </div>
            <p className="mt-16 text-center lg:text-left text-3xl mb-7 my-8 font-semibold">¡Muchas Gracias!</p>
        </div>
    );
}

export default Pago;