import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../Components/Header/Header';
import HeaderLower from '../../../Components/HeaderLower/HeaderLower';
import ver from '../../../assets/ver.svg';
import noVer from '../../../assets/no-ver.svg';
import { loginUser } from '../../../redux/actions';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Login = () => {
    const [showPass, setShowPass] = useState(false);
    const [mail, setMail] = useState('');
    const [clave, setClave] = useState('');
    const [error, setError] = useState('');
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const authError = useSelector(state => state.error);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('n');
    const queryCarrito = location.search == '?f=t' ? true : false;
    const queryChangePass = searchParams.get('c');
    const changePass = searchParams.get('changePass');

    const handleLogin = () => {
        try {
            if (emailRegex.test(mail)) {
                dispatch(loginUser({ mail, clave, queryCarrito }, setLoading));
                setError('');
            } else {
                setError('El email ingresado no es válido');
                setLoading(false);
            }
        } catch (error) {
            console.error(error)
        }
    };
    return (
        <div>
            <Header />
            <HeaderLower />
            <div className='flex flex-col pt-20'>
                <div className='bg-[#EFEFED] w-full lg:pt-10 pt-7'>
                    <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                        <p className='text-xs text-left lg:ml-16 ml-8 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> Login</p>
                        <p className='text-5xl my-10 lg:pb-20 pb-10'>
                            Ingresar
                        </p>
                    </div>
                </div>
                <div className='flex flex-col mt-10 lg:px-0 px-10 items-center'>
                    {authError || error && <p className='mr-[40%] font-semibold text-[#E40F1B] pr-4 mb-5 opacity-80 text-sm'>{authError || error}</p>}
                    {query &&
                        <p className='text-center font-semibold text-green-700 pr-4 mb-5 opacity-80 text-sm'>Se ha registrado el usuario</p>}
                    {queryChangePass &&
                        <p className='text-center font-semibold text-green-700 pr-4 mb-5 opacity-80 text-sm'>Se te ha enviado un correo con las instrucciones para el cambio de clave</p>
                    }
                    {changePass &&
                        <p className='text-center font-semibold text-green-700 pr-4 mb-5 opacity-80 text-sm'>Clave cambiada con éxito.</p>
                    }
                    <input
                        type="text"
                        onChange={(e) => setMail(e.target.value)}
                        placeholder='Email'
                        className='border lg:py-1 py-5 w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-sm lg:w-[60%]'
                    />
                    <div className='relative w-full lg:w-[60%]'>
                        <input onChange={(e) => setClave(e.target.value)} type={showPass ? "text" : "password"} placeholder='Contraseña' className='border lg:py-1 py-5 my-2.5 pl-2.5 w-[100%] hover:border-[#E40F1B] rounded-sm' />
                        <button className='absolute right-4 lg:mt-4 mt-8' onClick={() => setShowPass(!showPass)}>
                            {showPass ?
                                <img src={noVer} className='opacity-50' alt="" />
                                :
                                <img src={ver} className='opacity-50' alt="" />
                            }
                        </button>
                    </div>

                    <button
                        onClick={() => {
                            setLoading(true);
                            handleLogin();
                        }}
                        className='uppercase items-center flex justify-center bg-black text-white w-full lg:w-[60%] py-2 text-sm mt-2.5 mb-5 hover:bg-[#E40F1B] rounded-sm'
                    >
                        {loading ?
                            <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            : 'ENTRAR'}
                    </button>
                    <div className='flex lg:flex-row flex-col w-full justify-between mb-20 lg:text-sm text-[14px] opacity-50'>
                        <p className='lg:ml-[20%] my-4'>¿Olvidaste tu contraseña? <a className='border-gray-400 border-b-[0.5px] hover:text-[#E40F1B] hover:border-[#E40F1B]' href="recuperar">Recuperala acá</a></p>
                        <p className='lg:mr-[20%]'>¿No estás registrado? <a className='border-gray-400 hover:text-[#E40F1B] hover:border-[#E40F1B] border-b-[0.5px]' href="/registrarse">Registrate acá</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;