// src/Components/CookieConsentModal/CookieConsentModal.js

import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react'; // Asegúrate de tener lucide-react instalado

export default function CookieConsentModal() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Verifica si el usuario ya tomó una decisión
    const cookieChoice = localStorage.getItem('cookieChoice');
    if (!cookieChoice) {
      setIsVisible(true);
    } else if (cookieChoice === 'accepted') {
      // Si el usuario ya aceptó las cookies, carga el pixel
      loadMetaPixel();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieChoice', 'accepted');
    setIsVisible(false);
    loadMetaPixel(); // Inicializa el pixel aquí
    console.log('Consentimiento de cookies aceptado');
  };

  const handleReject = () => {
    localStorage.setItem('cookieChoice', 'rejected');
    setIsVisible(false);
    console.log('Consentimiento de cookies rechazado');
    // Puedes manejar la negación de cookies aquí si es necesario
  };

  const loadMetaPixel = () => {
    if (window.fbq) return;

    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );

    // Inicializar el pixel con tu ID
    window.fbq('init', '295314976975757'); // Reemplaza con tu ID real
    console.log('Meta Pixel inicializado');
    window.fbq('track', 'PageView');
    console.log('Evento PageView enviado');
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 md:px-40 bg-white dark:bg-white shadow-lg p-4 md:p-0 md:py-3 z-50">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4 relative">
        <p className="text-sm text-gray-700 flex-grow">
          Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. ¿Aceptas el uso de cookies?
        </p>
        <div className="flex items-center gap-4">
          <button
            onClick={handleReject}
            className="px-4 py-2 border border-gray-400 text-gray-400 rounded-md hover:bg-gray-400 hover:text-white transition"
          >
            Rechazar
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-red-600 transition"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
}
