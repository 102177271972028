import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCarrito from "../../ui/cardCarrito/CardCarrito";
import { getCarrito } from "../../redux/actions";
import { X } from "lucide-react";

const Carrito = ({ isOpen, setIsOpen, isNew }) => {
    const carritoRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const carrito = useSelector((state) => state.carrito);
    const precioDolar = useSelector((state) => state.dolarPrice);

    const carritoGlobal = token ? carrito : JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];

    useEffect(() => {
        dispatch(getCarrito(token));
        let timer;
        const segundos = carritoGlobal?.length === 0 ? 2700 : 1500;
        timer = setTimeout(() => {
            setLoading(true);
        }, segundos);

        return () => clearTimeout(timer);
    }, []);

    const precioTotal = carritoGlobal.reduce((previous, current) => {
        const itemPriceUSD = current.precio_dolar; // Ajusta según tu estructura
        const precioConIncremento = itemPriceUSD * precioDolar;
        console.log(itemPriceUSD)
        const porcentajePromo = Number(current.porcentaje_promo) || 0;
        const cantidadPromo = Number(current.cantidad_promo) || 0;

        let resultado;
        if (porcentajePromo) {
            resultado = precioConIncremento * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            resultado = precioConIncremento - cantidadPromo;
        } else {
            resultado = precioConIncremento;
        }

        const precioFinal = (resultado * current.cantidad).toFixed(0);

        return previous + (Number(current.precio * current.cantidad) || Number(precioFinal))
    }, 0);

    return (
        <div>
            {isOpen &&
                <div className="z-[2200] w-full absolute"
                    ref={carritoRef}
                    onClick={(e) => {
                        if (e.target === carritoRef.current) {
                            setIsOpen(false);
                        }
                    }}
                >
                    <div
                        className="fixed top-8 lg:w-1/3 w-full bottom-0 right-0 bg-white shadow-2xl z-50"
                    >
                        <div className="mx-7">
                            <h1 className="my-5 text-sm font-bold text-left">TU CARRITO {'>'} {carritoGlobal?.length} producto/s</h1>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="absolute top-5 right-7 text-gray-500 hover:text-gray-800 cursor-pointer"
                            >
                                <X className="w-5" />
                            </button>
                            <div className="border-t-2 border-opacity-50 mb-3"></div>
                            <div className="overflow-x-auto max-h-[calc(100vh-270px)]">
                                <div className="flex flex-col">
                                    {carritoGlobal?.length > 0 && loading ?
                                        carritoGlobal.map((item) => (
                                            <div key={item.idproducto}>
                                                <CardCarrito item={item} dolar={precioDolar} setLoading={setLoading} />
                                                <div className="border-t-2 my-2 border-opacity-30"></div>
                                            </div>
                                        )) :
                                        <div className="flex justify-center items-center mt-32 mb-60">
                                            {/* SVG del spinner */}
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            {carritoGlobal?.length > 0 ?
                                <div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row text-sm justify-between mb-1">
                                            <p className="font-medium">Totales:</p>
                                            <p>{precioTotal.toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            })}.-</p>
                                        </div>
                                        <div className="border-b-2"></div>
                                        <button className="border uppercase my-1 bg-[#E40F1B] text-[13px] py-2 rounded-md hover:bg-red-400 text-white mt-1">
                                            <a href="/carrito">Continuar Compra</a>
                                        </button>
                                        <a href="/carrito" className="border my-1 text-[13px] py-2 rounded-md hover:bg-gray-400 border-gray-400 hover:text-white">
                                            VER CARRITO
                                        </a>
                                    </div>
                                </div> : loading ? <div className="font-semibold">No hay productos</div> : null
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Carrito;
