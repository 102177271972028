// src/trackEvent.js

const eventQueue = [];

export const trackEvent = (eventName, eventParams = {}) => {
  if (typeof window.fbq !== 'undefined') {
    // Verifica si eventParams no está vacío
    const hasEventParams = eventParams && Object.keys(eventParams).length > 0;

    if (hasEventParams) {
      window.fbq('track', eventName, eventParams);
      console.log(`Evento ${eventName} enviado con parámetros:`, eventParams);
    } else {
      window.fbq('track', eventName);
      console.log(`Evento ${eventName} enviado sin parámetros.`);
    }
  } else {
    // Almacenar el evento en la cola
    eventQueue.push({ type: 'track', eventName, eventParams });
    console.log(`fbq no está definido. Evento ${eventName} almacenado en la cola.`);
  }
};

// Después de cargar el pixel, enviar eventos en cola
export const flushEventQueue = () => {
  if (typeof window.fbq !== 'undefined' && eventQueue.length > 0) {
    eventQueue.forEach(event => {
      if (event.type === 'track') {
        window.fbq('track', event.eventName, event.eventParams);
      } else if (event.type === 'trackCustom') {
        window.fbq('trackCustom', event.eventName, event.eventParams);
      }
      console.log(`Evento ${event.eventName} enviado desde la cola.`);
    });
    // Vaciar la cola
    eventQueue.length = 0;
  }
};
