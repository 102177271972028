import React, { useState, useEffect, useMemo } from 'react';
import trash from "../../assets/trash.svg";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getCarrito } from '../../redux/actions';

const CardCarritoPage = ({ item, dolar, setLoading, onChangeCart }) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const [localCant, setLocalCant] = useState(getInitialCantidad());

    function getInitialCantidad() {
        if (token) {
            return item.cantidad;
        } else {
            const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
            const producto = carritoLocal.find(prod => prod.idproducto === item.idproducto);
            return producto ? producto.cantidad : item.cantidad;
        }
    }

    const updateLocalCart = (idproducto, cantidad) => {
        const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
        const itemIndex = carritoLocal.findIndex(prod => prod.idproducto === idproducto);

        if (itemIndex !== -1) {
            carritoLocal[itemIndex].cantidad = cantidad;
            if (carritoLocal[itemIndex].cantidad <= 0) {
                carritoLocal.splice(itemIndex, 1);
            }
            localStorage.setItem('carrito-sce-product-manager', JSON.stringify(carritoLocal));
        } else {
            carritoLocal.push({ idproducto, cantidad });
            localStorage.setItem('carrito-sce-product-manager', JSON.stringify(carritoLocal));
        }
        // Notificamos al padre que hubo un cambio
        onChangeCart && onChangeCart();
    };

    const removeFromLocalCart = (idproducto) => {
        const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
        const updatedCart = carritoLocal.filter(prod => prod.idproducto !== idproducto);
        localStorage.setItem('carrito-sce-product-manager', JSON.stringify(updatedCart));
        onChangeCart && onChangeCart();
    };

    const handleCantidad = async (accion, idproducto) => {
        let updatedCant = localCant;

        if (accion === 'suma') {
            if (item.cantidadTotal !== undefined) {
                if (localCant < item.cantidadTotal) {
                    updatedCant = Number(localCant) + 1;
                } else {
                    console.warn(`No puedes agregar más de ${item.cantidadTotal} unidades.`);
                    return;
                }
            } else {
                updatedCant = Number(localCant) + 1;
            }
        } else if (accion === 'resta') {
            if (localCant > 1) {
                updatedCant = Number(localCant) - 1;
            }
        }

        setLocalCant(updatedCant);
        updateLocalCart(idproducto, updatedCant);

        if (token) {
            try {
                await axios.post(
                    `https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`,
                    { token, idproducto, cantidad: updatedCant },
                    { headers: { "Content-Type": "application/json" } }
                );
                dispatch(getCarrito(token));
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleClick = async (idproducto) => {
        setLoading(false);
        if (token) {
            try {
                await axios.post(
                    `https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/borrar-producto-carrito`,
                    { token, idproducto },
                    { headers: { "Content-Type": "application/json" } }
                );
                dispatch(getCarrito(token, true));
                onChangeCart && onChangeCart();
            } catch (error) {
                console.error(error);
            }
        } else {
            removeFromLocalCart(idproducto);
            setLocalCant(0); 
        }
        setLoading(true);
    };

    const handleChange = async (e, idproducto) => {
        const inputValue = parseInt(e.target.value, 10);
        let newLocalCant = 1;

        if (!isNaN(inputValue) && inputValue > 0) {
            newLocalCant = item.cantidadTotal !== undefined && inputValue > item.cantidadTotal
                ? item.cantidadTotal
                : inputValue;
        }

        setLocalCant(newLocalCant);
        updateLocalCart(idproducto, newLocalCant);

        if (token) {
            try {
                await axios.post(
                    `https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`,
                    { token, idproducto, cantidad: newLocalCant },
                    { headers: { "Content-Type": "application/json" } }
                );
                dispatch(getCarrito(token));
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (!token) {
            const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
            const producto = carritoLocal.find(prod => prod.idproducto === item.idproducto);
            if (producto) {
                setLocalCant(producto.cantidad);
            } else {
                setLocalCant(1);
            }
        }
    }, [token, item.idproducto]);

    const precioFinal = useMemo(() => {
        let resultado;
        let porcentajePromo = Number(item.porcentaje_promo) || 0;
        let cantidadPromo = Number(item.cantidad_promo) || 0;

        if (porcentajePromo) {
            resultado = item.precio_dolar * dolar * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            resultado = (item.precio_dolar * dolar) - cantidadPromo;
        } else {
            resultado = item.precio_dolar * dolar;
        }

        const total = resultado * localCant;
        return item.precio ? item.precio * localCant : Number(total.toFixed(0));
    }, [localCant, dolar, item.precio, item.precio_dolar, item.porcentaje_promo, item.cantidad_promo]);

    return (
        <div className="">
            <div className='flex flex-row lg:justify-between items-center text-sm my-4 lg:h-20'>
                <div className='flex lg:flex-row flex-col'>
                    <img
                        src={`https://sistema.depcsuite.com/productos/${(item.imageUrl || item.imagen)}`}
                        alt={item.nombre}
                        className='lg:h-24 w-24 border'
                    />
                    <h5 className="w-28 ml-4 mt-2 mr-4 text-left">{item.nombre}</h5>
                </div>
                <div className='lg:block hidden'>
                    <p>Entrega inmediata</p>
                </div>
                <div className='border w-24 ml-5 flex items-center justify-center'>
                    <button
                        className='font-semibold h-6 mr-3'
                        onClick={() => handleCantidad('resta', item.idproducto)}
                        disabled={localCant <= 1}
                    >
                        -
                    </button>
                    <input
                        type="number"
                        className={`h-7 w-12 text-center`}
                        onChange={(e) => handleChange(e, item.idproducto)}
                        value={localCant}
                        min="1"
                        max={item.cantidadTotal || ''}
                    />
                    <button
                        className='font-semibold h-6 ml-3'
                        onClick={() => handleCantidad('suma', item.idproducto)}
                        disabled={item.cantidadTotal !== undefined && localCant >= item.cantidadTotal}
                    >
                        +
                    </button>
                </div>
                <div className="">
                    <div className='flex flex-row w-20 ml-5'>
                        <span className="ml-2 whitespace-nowrap">
                            Precio: {Number(precioFinal).toLocaleString("es-AR", {
                                style: "currency",
                                currency: "ARS",
                                maximumFractionDigits: 0,
                            })}-
                        </span>
                    </div>
                </div>
                <button onClick={() => handleClick(item.idproducto)}>
                    <img src={trash} className='ml-2 w-4' alt="Eliminar" />
                </button>
            </div>
        </div>
    );
};

export default CardCarritoPage;