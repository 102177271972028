// CardCarritoPageMobile.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import trash from "../../assets/_quitar_producto.svg";
import { useDispatch } from 'react-redux';
import { getCarrito } from '../../redux/actions';

const CardCarritoPageMobile = ({ item, dolar, setLoading }) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");

    // Inicializar 'localCant' correctamente
    const [localCant, setLocalCant] = useState(() => {
        const initialCant = parseInt(item.cantidad, 10);
        return isNaN(initialCant) || initialCant <= 0 ? 1 : initialCant;
    });

    // Actualizar 'localCant' si 'item.cantidad' o 'item.idproducto' cambia
    useEffect(() => {
        const updatedCant = parseInt(item.cantidad, 10);
        if (!isNaN(updatedCant) && updatedCant > 0) {
            setLocalCant(updatedCant);
        } else {
            setLocalCant(1);
            updateLocalCart(item.idproducto, '1'); // Guardar como cadena
        }
    }, [item.cantidad, item.idproducto]);

    // Función para actualizar 'cantidad' en localStorage
    const updateLocalCart = (idproducto, cantidad) => {
        const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
        const itemIndex = carritoLocal.findIndex(prod => String(prod.idproducto) === String(idproducto));

        if (itemIndex !== -1) {
            carritoLocal[itemIndex].cantidad = String(cantidad); // Guardar como cadena
            if (parseInt(carritoLocal[itemIndex].cantidad, 10) <= 0) {
                carritoLocal.splice(itemIndex, 1);
                console.log(`Producto con idproducto ${idproducto} eliminado del carrito por cantidad <= 0`);
            } else {
                console.log(`Cantidad actualizada en localStorage para idproducto ${idproducto}:`, carritoLocal[itemIndex].cantidad);
            }
            localStorage.setItem('carrito-sce-product-manager', JSON.stringify(carritoLocal));
        }
    };

    // Función para eliminar producto del carrito en localStorage
    const removeFromLocalCart = (idproducto) => {
        const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
        const updatedCart = carritoLocal.filter(prod => String(prod.idproducto) !== String(idproducto));
        localStorage.setItem('carrito-sce-product-manager', JSON.stringify(updatedCart));
        console.log(`Producto con idproducto ${idproducto} eliminado de localStorage.`);
    };

    // Manejar incremento y decremento de 'cantidad'
    const handleCantidad = async (accion, idproducto) => {
        let updatedCant = localCant;
        const cantidadTotal = Number(item.cantidadTotal);

        if (accion === 'suma') {
            if (!isNaN(cantidadTotal)) {
                updatedCant = updatedCant < cantidadTotal ? updatedCant + 1 : cantidadTotal;
            } else {
                console.warn(`Cantidad total no definida para idproducto ${idproducto}. Asignando Infinity.`);
                updatedCant += 1;
            }
        } else if (accion === 'resta' && updatedCant > 1) {
            updatedCant -= 1;
        }

        setLocalCant(updatedCant);

        if (token) {
            // Usuario autenticado: actualizar en el backend
            try {
                await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`, {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ token, idproducto, cantidad: String(updatedCant) }), // Enviar como cadena
                    isBase64Encoded: false,
                });
                dispatch(getCarrito(token));
                console.log(`Cantidad actualizada en el backend para idproducto ${idproducto}:`, updatedCant);
            } catch (error) {
                console.error('Error al actualizar la cantidad en el backend:', error);
            }
        } else {
            // Usuario no autenticado: actualizar en localStorage
            updateLocalCart(idproducto, updatedCant);
        }
    };

    // Manejar eliminación de producto del carrito
    const handleClick = async (idproducto) => {
        setLoading(false);
        if (token) {
            // Usuario autenticado: eliminar del backend
            try {
                await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/borrar-producto-carrito`, {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ token, idproducto }),
                    isBase64Encoded: false,
                });
                dispatch(getCarrito(token));
                console.log(`Producto con idproducto ${idproducto} eliminado del backend.`);
            } catch (error) {
                console.error('Error al eliminar el producto del backend:', error);
            }
        } else {
            // Usuario no autenticado: eliminar de localStorage
            removeFromLocalCart(idproducto);
        }
        setLoading(true);
    };

    // Manejar cambios directos en el input de 'cantidad'
    const handleChange = async (e, idproducto) => {
        const inputValue = e.target.value;
        let newLocalCant;

        if (!isNaN(inputValue) && parseInt(inputValue, 10) > 0) {
            const parsedValue = parseInt(inputValue, 10);
            if (parsedValue > item.cantidadTotal) {
                newLocalCant = item.cantidadTotal;
            } else {
                newLocalCant = parsedValue;
            }
        } else {
            newLocalCant = 1;
        }

        setLocalCant(newLocalCant);

        if (token) {
            // Usuario autenticado: actualizar en el backend
            try {
                await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`, {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ token, idproducto, cantidad: String(newLocalCant) }), // Enviar como cadena
                    isBase64Encoded: false,
                });
                dispatch(getCarrito(token));
                console.log(`Cantidad actualizada en el backend para idproducto ${idproducto}:`, newLocalCant);
            } catch (error) {
                console.error('Error al actualizar la cantidad en el backend:', error);
            }
        } else {
            // Usuario no autenticado: actualizar en localStorage
            updateLocalCart(idproducto, newLocalCant);
            console.log(`Cantidad actualizada en localStorage para idproducto ${idproducto}:`, newLocalCant);
        }
    };

    // Cálculos de precio
    const itemPriceUSD = item.precio_dolar || 0;
    const itemPriceUSDOffert = item.precio_oferta_dolares || 0;
    const precioConIncremento = itemPriceUSDOffert && Number(item.porcentaje_promo) <= 0 ? itemPriceUSDOffert * dolar : item.precio_dolar * dolar;
    const precioConIncrementoOferta = itemPriceUSDOffert * dolar;
    const descuentoOferta = precioConIncrementoOferta;
    const descuento = precioConIncremento;
    const precioFinal = ((precioConIncremento || descuento) * localCant).toFixed(0);
    let porcentajePromo = Number(item.porcentaje_promo) || 0;
    let cantidadPromo = Number(item.cantidad_promo) || 0;

    let resultado;
    let label;
    if (porcentajePromo) {
        resultado = precioConIncremento * (1 - porcentajePromo / 100);
        label = `${porcentajePromo}% OFF`;
    } else if (cantidadPromo) {
        resultado = precioConIncremento - cantidadPromo;
    }

    const precioConDescuentoAdicional = resultado * 0.8;
    const precioFinal2 = precioConDescuentoAdicional.toFixed(0);
    const calculateInstallment = () => (resultado / 6).toFixed(2);
    const porcentajeDiferencia = itemPriceUSDOffert
        ? `${Math.abs(
            ((100 - (itemPriceUSDOffert * 100) / itemPriceUSD) + porcentajePromo).toFixed(0)
        )}% OFF`
        : '';
    const [installmentPrice, installmentDecimal] = calculateInstallment().split('.');

    return (
        <div className="flex flex-col my-4 mx-3 border-b pb-4">
            <div className='flex flex-row items-center justify-between text-sm lg:h-20'>
                <div className='flex flex-row items-center'>
                    <img
                        src={`https://sistema.depcsuite.com/productos/${(item.imagen || item.imageUrl)}`}
                        alt={item.nombre}
                        className='w-20 h-20 object-cover border rounded'
                    />
                    <div className="ml-3 flex-1">
                        <h5 className="font-semibold text-[14px] mb-2 text-left">{item.nombre}</h5>
                        <div className='flex items-center border rounded'>
                            <button
                                className='font-semibold h-8 w-8 text-center'
                                onClick={() => handleCantidad('resta', item.idproducto)}
                                disabled={localCant <= 1}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                onChange={(e) => handleChange(e, item.idproducto)}
                                className='h-8 w-12 text-center'
                                value={localCant}
                                min="1"
                            />
                            <button
                                className='font-semibold h-8 w-8 text-center'
                                onClick={() => handleCantidad('suma', item.idproducto)}
                                disabled={localCant >= item.cantidadTotal}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <button onClick={() => handleClick(item.idproducto)}>
                        <img src={trash} className='w-6' alt="Eliminar" />
                    </button>
                    {porcentajePromo !== 0 || cantidadPromo !== 0 ? (
                        <span className="text-xs text-red-600">
                            {`${Number(item.precio || resultado).toLocaleString("es-AR", {
                                style: "currency",
                                currency: "ARS",
                                maximumFractionDigits: 0,
                            })} Promo CyberOFF ${porcentajePromo > 0 ? `${porcentajePromo}%` : cantidadPromo > 0 ? ` - ${cantidadPromo}` : ''}`}
                        </span>
                    ) : (
                        <span className="text-xs text-red-600">
                            {Number(item.precio || precioFinal).toLocaleString("es-AR", {
                                style: "currency",
                                currency: "ARS",
                                maximumFractionDigits: 0,
                            })}
                        </span>
                    )}
                </div>
            </div>
            {resultado && (
                <div className="text-sm font-regularQuicksand mt-2">
                    {Number(precioConIncremento).toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        maximumFractionDigits: 0,
                    })}{' '}
                    de lista
                </div>
            )}
            {resultado && (
                <div className="flex font-mediumQuicksand mt-2 items-center justify-center">
                    <span className="text-[12px] mr-1">6 cuotas de </span>
                    <span className="text-[12px] font-bold">
                        {Number(installmentPrice).toLocaleString('es-AR', {
                            style: 'currency',
                            currency: 'ARS',
                            maximumFractionDigits: 0,
                        })}
                    </span>
                    <span className="text-[7px] ml-1">
                        {installmentDecimal}
                    </span>
                </div>
            )}
        </div>
    );
};

export default CardCarritoPageMobile;