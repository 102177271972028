// src/App.js

import React, { useEffect } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import HeaderLower from './Components/HeaderLower/HeaderLower';
import NewsletterComponent from './Components/NewsletterComponent/NewsletterComponent';
import Footer from './Components/Footer/Footer';
import FooterMobile from './Components/FooterMobile/FooterMobile';
import CookieConsentModal from './Components/CookieConsentModal/CookieConsentModal';
import { Switch, Route, useLocation } from 'react-router-dom';
import Puffs from './pages/Puffs/puffs';
import DetallesProducto from './pages/Products/products';
import Home from './pages/Home/home';
import Mesas from './pages/Mesas/mesas';
import Deco from './pages/Deco/deco';
import Exterior from './pages/Exterior/exterior';
import Sillas from './pages/Sillas/sillas';
import Otros from './pages/Otros/Otros';
import Entrega from './pages/entrega-inmediata/entrega-inmediata';
import Collection from './pages/Collection/Collection';
import Sale from './pages/Sale/Sale';
import Set from './pages/Set/Set';
import Buscador from './pages/Buscador/buscador';
import Registro from './pages/Usuarios/Registro/Registro';
import Login from './pages/Usuarios/Login/Login';
import Recuperar from './pages/Usuarios/Recuperar/Recuperar';
import MisDatos from './pages/Usuarios/Mis-datos/MisDatos';
import CarritoPage from './pages/Carrito/carrito';
import Atencion from './pages/Atencion/Atencion';
import Cambiar from './pages/Usuarios/Cambiar/cambiar';
import Nosotros from './pages/Nosotros/Nosotros';
import { initMercadoPago } from '@mercadopago/sdk-react';
import Shop from './pages/Shop/Shop';
import EditarDomiclio from './pages/Usuarios/Mis-datos/Datos/editar-domicilio/editar-domicilio';
import Ofertas from './pages/Ofertas/Ofertas';
import ReactPixel from 'react-facebook-pixel';

// Inicializar Mercado Pago
initMercadoPago("APP_USR-27de1c87-5dbc-4bf1-b630-aca8e0a263f6");

/**
 * Hook personalizado para rastrear cambios de página
 */
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);
}

/**
 * Componente que envuelve las rutas y aplica el rastreo de páginas.
 */
function TrackingRoutes() {
  usePageTracking();

  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/registrarse' component={Registro} />
      <Route path='/carrito' component={CarritoPage} />
      <Route path='/atencion-al-cliente/:inicio' component={Atencion} />
      <Route path='/nosotros' component={Nosotros} />
      <Route path='/recuperar' component={Recuperar} />
      <Route path='/cambiar-clave/:token' component={Cambiar} />
      <Route exact path='/mis-datos/editar-domicilio' component={EditarDomiclio} />
      <Route exact path='/mis-datos/:fav' render={(props) => <MisDatos {...props} favorito={true} />} />
      <Route exact path='/mis-datos' component={MisDatos} />
      <Route path='/ingresar' component={Login} />
      <Route exact path='/buscador' component={Buscador} />
      <Route exact path='/set-de-productos' component={Set} />
      <Route exact path='/entrega-inmediata' component={Entrega} />
      <Route exact path='/sale' component={Sale} />
      {/* <Route exact path='/cyber-off' component={Ofertas} /> */}
      <Route exact path='/collection' component={Collection} />
      <Route exact path='/mesas' component={Mesas} />
      <Route path='/mesas/:tipoDeProducto' component={Mesas} />
      <Route exact path='/exterior' component={Exterior} />
      <Route path='/exterior/:tipoDeProducto' component={Exterior} />
      <Route exact path='/sillas' component={Sillas} />
      <Route path='/sillas/:tipoDeProducto' component={Sillas} />
      <Route exact path='/otros' component={Otros} />
      <Route path='/otros/:tipoDeProducto' component={Otros} />
      <Route exact path='/deco-iluminaciones' component={Deco} />
      <Route path='/deco-iluminaciones/:tipoDeProducto' component={Deco} />
      <Route exact path='/puffs-sillones' component={Puffs} />
      <Route path='/puffs-sillones/:tipoDeProducto' component={Puffs} />
      <Route path='/shop/:tipoDeProducto' component={Shop} />
      <Route exact path='/shop' component={Shop} />
      <Route path='/producto/:paramsSlug' component={DetallesProducto} />
    </Switch>
  );
}

/**
 * Componente principal de la aplicación.
 */
function App() {
  return (
    <div className="App">
      {/* Modal de Consentimiento de Cookies */}
      <CookieConsentModal />

      {/* Rutas con Rastreo de Páginas */}
      <TrackingRoutes />

      {/* Otros Componentes */}
      <NewsletterComponent />
      <div className='lg:block hidden'>
        <Footer />
      </div>
      <div className='lg:hidden'>
        <FooterMobile />
      </div>
    </div>
  );
}

export default App;
